@import './styles/constants.css';

html, body, #root {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--offwhite);
  min-height: 100%;
}

.main-wrapper {
  width: 440px;
  position: relative;
  margin: -16px 0 40px;
  padding-block: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--offwhite);
  border: 4px solid var(--black);
  border-radius: 2% 4% 1% 3% / 1% 2% 2% 3%;
}

.main-wrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  border-radius: 1% 2% 2% 3% / 2% 4% 1% 3%;
  border: 1px solid var(--black);
  transform: translate(-50%, -49%) scale(1.02) rotate(-1deg);
  pointer-events: none;
}


@media screen and (max-width: 448px) {
  .main-wrapper {
    width: calc(100% - 40px);
    margin: -16px 0 24px;
  }
}