@import '../../styles/constants.css';

.svgWrapper {
  margin: 8px auto 0;
}

.selfPortrait {
  animation: fadeIn 2s ease-in;
  width: 180px;
  transform: rotate(2deg);
}

.shp0 { 
  fill: var(--grey);
} 

.shp1 {
  fill: var(--smoke);
} 

.shp2, .eyes {
  fill: var(--black);
} 

.eyes {
  animation: blink 3s infinite;
}

.laptopHidden, .lightHidden {
  display: none;
}

.pharmacistCoat {
  transform: rotate(2deg);
  position: absolute;
  top: -95px;
  left: calc(50% - 90px);
}

.laptop {
  animation: slideInFromLeft 1s cubic-bezier(0.215, 0.610, 0.355, 1);
  position: absolute;
  top: 122px;
  left: calc(50% - 70px);
  z-index: 1;
}

.light {
  position: absolute;
  top: -20px;
  left: calc(50% - 90px);
  fill: var(--seafoam);
  opacity: 0;
  mix-blend-mode: lighten;
  z-index: 2;
  animation: lightFade 5s ease-in-out infinite;
  animation-delay: 1.5s;
}

@keyframes lightFade {
  0%, 100% {
    opacity: 0.1;
  }
  33% {
    opacity: 0.15;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

@keyframes blink {
  0%, 4% {
    transform-origin: center;
    transform: none
  } 
  1%, 3% {
    transform-origin: center;
    transform: scale(1, 0.15) translateY(-20px) skew(0deg, -55deg)
  }
}

@keyframes slideInFromLeft {
  from {
    left: -100px;
  }
  to {
    left: calc(50% - 70px);
  }
}
