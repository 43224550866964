@import '../../styles/constants.css';

.headingTitle {
  font-family: 'Gloria Hallelujah', monospace;
  margin: 16px 0 0;
  font-size: 36px;
  text-align: center;
}

.subGroup {
  display: flex;
  justify-content: center;
  margin-block-end: 24px;
  font-size: 16px;
}

.subTitle {
  font-family: 'Gloria Hallelujah', monospace;
  margin: 0;
  text-align: center;
  font-size: inherit;
}

.divider {
  font-family: 'Gloria Hallelujah', monospace;
  font-size: inherit;
  padding-inline: 8px;
}

.listGroup {
  margin: 0px 36px 24px;
  display: flex;
  flex-direction: column;
  padding-inline-start: 16px;
}

.listItem {
  list-style-type: '\2022';
  padding: 4px 0px 4px 8px;
  font-family: 'Roboto Mono', monospace;
  font-size: 10px;
}

.highlight, .emphasise {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid var(--seafoam);
}

.highlight:hover, .highlight:active {
  background-color: var(--seafoam);
  border-bottom: 2px solid var(--black);
  cursor: pointer;
}

.aboutButton {
  font-family: 'Roboto Mono', monospace;
  font-size: 10px;
  text-transform: uppercase;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  padding: 0;
  margin-inline: 8px;
}

@media screen and (max-width: 448px) {
  .headingTitle {
    font-size: 28px;
  }

  .subGroup {
    font-size: 12px;
    margin-block-end: 16px;
  }

  .divider {
    padding-inline: 4px;
  }

  .listGroup {
    margin: 0px 16px 16px;
    display: flex;
    flex-direction: column;
    padding-inline-start: 16px;
  }
}
